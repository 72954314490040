import { useEffect, useState } from 'react';
import {
  Container,
  Content,
  StyleEquipamentInfo,
  StyleMaintenanceInfo,
  StyledBoxBorder,
  StyledBoxContent,
  StyledBoxLeft,
  StyledClientInfo,
  StyledEquipmentPhoto,
  StyledLocationBox,
  StyledLocationLogo,
  StyledLocationLogoContainer,
  StyledPreventiveMaintenance,
} from '../styled';

import imgPlaceholder from 'assets/images/placeholder_img.jpg';
import SocialLinks from '../components/socials';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-right.svg';

const Ambiente = ({ code }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addresses, setAddresses] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_LIGHTHOUSE_FILTERS_API + `/v1/qrcode/public?QrCode=${code}&IgnoreCounts=true`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setData(data);

        const addressesResponse = await fetch(
          `${process.env.REACT_APP_LIGHTHOUSE_FILTERS_API}/v1/homepageempresa/${data.site.empresa}`,
        );
        const addressesData = await addressesResponse.json();

        setAddresses(addressesData);
      } catch (error) {
        console.error('Erro:', error);
        setError(error.toString());
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [code]);

  if (isLoading) {
    return <div>Carregando...</div>;
  }
  if (error) {
    return <div>Não autorizado</div>;
  }

  const socialLinks = {
    instagram: addresses.instagran || null,
    facebook: addresses.facebook || null,
    linkedin: addresses.linkedin || null,
    site: addresses.url || null,
  };

  return (
    <Container>
      <Content>
        <StyledBoxBorder>
          <StyledLocationLogoContainer>
            <StyledLocationLogo
              src={
                data.site?.empresaObj?.logomarcaPath
                  ? `${process.env.REACT_APP_URL_IMAGES}${data.site.empresaObj.logomarcaPath}`
                  : imgPlaceholder
              }
              alt="Logo Cliente"
            />
          </StyledLocationLogoContainer>
          <StyledClientInfo>
            <div>
              <h2>{data.site?.empresaObj?.nome || ''}</h2>
              <a href={'/clientes/' + addresses?.subdominio}>
                Saiba mais <ChevronIcon />
              </a>
            </div>
            <h4>Endereço</h4>
            <p>
              {addresses.endereco ? addresses.endereco + ',' : ' '}{' '}
              {addresses.numero ? addresses.numero?.replace(/\D/g, '') + '-' : ''}{' '}
              {addresses.cidadeObj?.nome ? addresses.cidadeObj.nome + ',' : ''}{' '}
              {addresses.cidadeObj?.estadoNome ? addresses.cidadeObj?.estadoNome : ''}
              {addresses.cep ? ' - ' + addresses.cep : ''}
            </p>
            {addresses.telefone ? (
              <>
                <h4>Contato</h4>
                <p>{addresses.telefone?.replace(/_$/, '')}</p>
              </>
            ) : null}
            <SocialLinks links={socialLinks} />
          </StyledClientInfo>
          <StyleEquipamentInfo>
            <h2>Ambiente</h2>
            <div>
              <StyledEquipmentPhoto
                src={data.area?.fotoPath ? `${process.env.REACT_APP_URL_IMAGES}${data.area.fotoPath}` : imgPlaceholder}
                alt="Foto do Ambiente"
              />

              <span>
                <span>
                  <h3>Nome da área</h3>
                  <p>{data.area?.nome || '-'}</p>
                </span>
                <span>
                  <h3>Identificação</h3>
                  <p>{data.area?.identificacaoAmbiente || '-'}</p>
                </span>
              </span>
            </div>
          </StyleEquipamentInfo>
          <StyleMaintenanceInfo>
            <h2>Manutenção</h2>
            <h3>Possui ocorrências em aberto?</h3>
            <p>{data.qtdOcorrenciasPendentes ? 'Sim' : 'Não'}</p>
            <h3>
              Tem Atividades Preventivas realizadas nos <br /> últimos 30 dias?
            </h3>
            <p>{data.qtdAtividadesUltimoMes ? 'Sim' : 'Não'}</p>
            <button onClick={() => window.open('https://software.leankeep.com/login', '_blank')}>
              ACESSAR LEANKEEP <span></span>
            </button>
          </StyleMaintenanceInfo>
        </StyledBoxBorder>
        <StyledBoxLeft>
          <StyledBoxContent>
            <div>
              <h2>Especificações do Ambiente</h2>
              <span>
                <span>
                  <h3>Quantidade de Equipamentos</h3>
                  <p>{data.area?.quantidadeEquipamentos || '-'}</p>
                </span>
                <span>
                  <h3>Área (m²)</h3>
                  <p>{data.area?.espacoOcupado || '-'}</p>
                </span>
              </span>
              <h2>Dados do Ambiente Climatizado</h2>
              <span>
                <span>
                  <h3>Tipo de Atividade</h3>
                  <p>{data.area?.tipoAtividade || '-'}</p>
                </span>
                <span>
                  <h3>Área Climatizada</h3>
                  <p>{data.area?.areaClimatizada || '-'}</p>
                </span>
                <span>
                  <h3>Carga Térmica</h3>
                  <p>{data.area?.cargaTermica ? `${data.area.cargaTermica} ${data.area.unidadeCargaTermica}` : '-'}</p>
                </span>
                <span>
                  <h3>Nº de Ocupantes Fixos</h3>
                  <p>{data.area?.ocupantesFixos || '-'}</p>
                </span>
                <span>
                  <h3>Nº de Ocupantes Flutuantes</h3>
                  <p>{data.area?.ocupantesFlutuantes || '-'}</p>
                </span>
              </span>
            </div>
          </StyledBoxContent>
          <StyledLocationBox>
            <h2>Local</h2>
            <h3>Unidade de manutenção</h3>
            <p>{data.site?.nome || '-'}</p>
            <h3>Grupo de Áreas</h3>
            <p>{data.area?.grupoAreaObj?.agrupamento || '-'}</p>
            <h3>Subgrupo de Áreas</h3>
            <p>{data.area?.subGrupoAreaObj?.nome || '-'}</p>
          </StyledLocationBox>
          <StyledPreventiveMaintenance>
            <div>
              <span className="firstSpan">
                <h2>Maximize a eficiência em manutenção preventiva</h2>
                <p>Descubra como a Leankeep pode revolucionar sua gestão de manutenção preventiva.</p>
                <button>Saiba mais</button>
              </span>
              <span className="secondSpan"></span>
            </div>
          </StyledPreventiveMaintenance>
        </StyledBoxLeft>
      </Content>
    </Container>
  );
};

export default Ambiente;
